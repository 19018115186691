import { Block, Checkbox, Text } from '@actovos-consulting-group/ui-core';
import React, { useEffect } from 'react';
import { useSet } from 'react-use';
import styled from 'styled-components';

const Wrapper = styled(Block)`
  column-count: 3;

  @media (max-width: 750px) {
    column-count: 1;
  }
`;

const Required = styled(Text)`
  position: absolute;
  left: -5px;
  top: 2px;
`;

const SelectIndustries = ({
  industries,
  onChange,
  error,
  field,
  initialValues = [],
}) => {
  const [set, { has, toggle }] = useSet(new Set(initialValues));
  const handleChange = val => {
    toggle(val);
  };

  useEffect(() => {
    onChange([...set]);
  }, [set]);

  const isMaxed = [...set].length >= 4;
  return (
    <Block position="relative">
      <Required color="red"> *</Required>
      <Text fontSize={17} mb={30} ml="9px">
        {field.label}
      </Text>
      <Wrapper p={2}>
        {industries.map(industry => (
          <Block key={industry}>
            <Checkbox
              isDisabled={isMaxed && !has(industry)}
              label={industry}
              on={has(industry)}
              onChange={checked => handleChange(industry, checked)}
            />
          </Block>
        ))}
      </Wrapper>
      {error && (
        <Text color="red" fontSize={12}>
          {error}
        </Text>
      )}
    </Block>
  );
};

export default SelectIndustries;
