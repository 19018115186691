import { Block, Button, Flex, Text } from '@actovos-consulting-group/ui-core';
import { Link } from 'gatsby';
import axios from 'axios';
import React from 'react';
import { useAsync } from 'react-use';
import Pricing from './components/pricing';

const FantasticPage = () => {
  const { value } = useAsync(async () => {
    const {
      data: { place },
    } = await axios.get(`${process.env.GATSBY_API_URL}/queue`);
    return place;
  });

  return (
    <Block mb={100}>
      <Flex flexDirection={{ _: 'column-reverse', xs: 'row' }} my={30}>
        <Block flex={1}>
          <Block width={{ _: '100%', xs: 400 }}>
            <img src="/img/fantastic.gif" alt="fantastic" width="100%" />
          </Block>
          <Text fontSize={40} fontWeight="bold" mb={3} mt={5}>
            Fantastic!
          </Text>
          <Text mt={4} fontSize={24} as="p">
            We will get to your product as soon as possible!
          </Text>
          {value && (
            <Text fontSize={18} as="p">
              As of now, you&apos;re number {value} in the queue. We take time
              to review each submission and will notifiy you when it&apos;s
              done.
            </Text>
          )}
          <Text fontSize={18} as="p">
            If you&apos;re interested in getting reviewed sooner you can support{' '}
            <Text as="span" style={{ textDecoration: 'line-through' }}>
              our dogs
            </Text>{' '}
            us below.
          </Text>
        </Block>
      </Flex>
      <Pricing />
      <Text fontSize={18} mt={20}>
        Purchasing any of the options above does not guarantee a high score. We
        may need money to cover overhead but aren&lsquo;t sellouts.
      </Text>
      <Text fontSize={18} mt={20}>
        No refunds. No take backs. If you ask, we&lsquo;ll roast you even
        harder.
      </Text>
      <Flex mt={20} justifyContent="flex-end">
        <Button as={Link} to="/products" size="md">
          No Thanks
        </Button>
      </Flex>
    </Block>
  );
};

export default FantasticPage;
