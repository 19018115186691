import { Button, Flex } from '@actovos-consulting-group/ui-core';
import { RiArrowRightCircleFill } from '@react-icons/all-files/ri/RiArrowRightCircleFill';
import { Link, navigate } from 'gatsby';
import React from 'react';
import useApplication from '../../../../hooks/use-application';

const BottomNav = ({ step: { leftButton, rightButton, step }, valid }) => {
  const { setLocalValues, values } = useApplication();
  return (
    <Flex mt={20} justifyContent="space-between" alignItems="center">
      {leftButton && <Link to={leftButton.path}>{leftButton.text}</Link>}
      {rightButton && (
        <Button
          onClick={() => {
            setLocalValues({
              completedSteps: [...new Set([...values.completedSteps, step])],
            });
            navigate(rightButton.path);
          }}
          type={rightButton.type}
          style={{ textDecoration: 'none' }}
          rightIconProps={{ size: 20 }}
          rightIcon={RiArrowRightCircleFill}
          disabled={!valid}
        >
          {rightButton.text}
        </Button>
      )}
    </Flex>
  );
};

export default BottomNav;
