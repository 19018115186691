import { Container } from '@actovos-consulting-group/ui-core';
import { Redirect, Router } from '@reach/router';
import React from 'react';
import Layout from '../../components/layout';
import SubmitAppPage from '../../components/scenes/submit';
import FantasticPage from '../../components/scenes/submit/fantastic';
import SEO from '../../components/seo';

const Submit = () => (
  <Layout bg="reallyLightGrey">
    <SEO title="Submit Your Product" />
    <Container maxWidth={1000}>
      <Router basepath="/submit">
        <FantasticPage path="/fantastic" />
        <SubmitAppPage path="/step/*" />
        <Redirect noThrow from="/" to="step/1" />
      </Router>
    </Container>
  </Layout>
);

export default Submit;
