const isBrowser = () => typeof window !== 'undefined';

export const isSafari =
  (isBrowser() && /constructor/i.test(window.HTMLElement)) ||
  // eslint-disable-next-line func-names
  (function (p) {
    return p.toString() === '[object SafariRemoteNotification]';
  })(
    // eslint-disable-next-line dot-notation
    (isBrowser() && !window['safari']) ||
      // eslint-disable-next-line no-undef
      (typeof safari !== 'undefined' && safari.pushNotification),
  );

export const isChrome =
  isBrowser() &&
  !!window.chrome &&
  (!!window.chrome.webstore || !!window.chrome.runtime);
