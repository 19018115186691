import { Block, Flex } from '@actovos-consulting-group/ui-core';
import firebase from 'firebase/app';
import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import styled, { css } from 'styled-components';
import useApplication from '../hooks/use-application';

const DropWrapper = styled(Block)`
  position: relative;
  min-height: 150px;
`;

const Overlay = styled(Flex)`
  ${p => css`
    position: absolute;
    z-index: 99;
    background-color: rgba(32, 38, 43, 0.9);
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    align-items: center;
    justify-content: center;
    border: 5px dashed ${p.theme.colors.mutedGrey};
  `}
`;

const DropToUploadText = styled.h3`
  color: white;
  font-size: 30px;
`;

const ImageUpload = ({
  initialFiles,
  maxFiles = 5,
  accept,
  children,
  onUpload,
  subPath,
}) => {
  const [uploading, setLoading] = useState(false);
  const {
    values: { appID },
  } = useApplication();
  const [files, setFiles] = useState(initialFiles);
  const storageRef = firebase.storage().ref();
  const getChildRef = file =>
    storageRef.child(`applications/${appID}/${subPath}/${file.name}`);

  const onDropAccepted = useCallback(
    async acceptedFiles => {
      setLoading(true);
      await Promise.all(acceptedFiles.map(file => getChildRef(file).put(file)));

      const filesToSet = [...files, ...acceptedFiles];
      setFiles(filesToSet);
      await onUpload(filesToSet);
      setLoading(false);
    },
    [files],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept,
    onDropAccepted,
    maxSize: 10000000,
    maxFiles,
    onDropRejected: rejectedFiles => {
      rejectedFiles.forEach(({ errors }) => {
        // eslint-disable-next-line no-alert
        errors.forEach(({ message }) => alert(message));
      });
    },
  });

  const onDelete = async (e, file) => {
    e.stopPropagation();
    setFiles(files.filter(f => f.name !== file.name));
    await getChildRef(file).delete();
  };

  return (
    <DropWrapper borderRadius={10} bg="lightGrey" {...getRootProps()}>
      {isDragActive && (
        <Overlay>
          <DropToUploadText>Drop to Upload</DropToUploadText>
        </Overlay>
      )}
      <input {...getInputProps()} />
      {children({ files, onDelete, uploading })}
    </DropWrapper>
  );
};

export default ImageUpload;
