import { Block, Flex, Input, Text } from '@actovos-consulting-group/ui-core';
import { IoMdEye } from '@react-icons/all-files/io/IoMdEye';
import { IoMdEyeOff } from '@react-icons/all-files/io/IoMdEyeOff';
import React from 'react';
import { Field as RField } from 'react-final-form';
import styled from 'styled-components';

const Content = styled(Block)`
  border: 1px solid ${p => p.theme.colors.lightGrey};
`;

const StyledInput = styled(Input)`
  border-bottom: 1px solid ${p => p.theme.colors.lightGrey};

  textarea {
    box-shadow: none;
  }

  label {
    div {
      position: absolute;
      left: -13px;
      top: 2px;
    }
  }
`;

const StepContent = ({ step }) => {
  const renderContent = field => {
    return (
      <Block mt={40} key={field.name}>
        <RField name={field.name} validate={field.validate}>
          {({ input, meta }) => {
            const hasError =
              meta.error && (meta.touched || !!input.value) && meta.error;
            return (
              <>
                {field.component ? (
                  field.component({ input, meta, field, error: hasError })
                ) : (
                  <StyledInput
                    {...field}
                    size="xl"
                    error={hasError}
                    variant="bottomBorder"
                    {...input}
                  />
                )}
                <Flex mt={hasError ? 4 : 2}>
                  {field.isPrivate ? <IoMdEyeOff /> : <IoMdEye />}
                  {field.helpText && (
                    <Text ml={2} fontSize={12}>
                      {field.helpText}
                    </Text>
                  )}
                </Flex>
              </>
            );
          }}
        </RField>
      </Block>
    );
  };

  return (
    <Content bg="white" p={30} pb={50}>
      <Text as="h2" fontSize={{ _: 20, xs: 40 }} m={0}>
        {step.formTitle}
      </Text>
      <Text mt={20}>{step.instructions}</Text>
      <Text mt={20} fontSize={14}>
        <IoMdEyeOff style={{ marginRight: 10 }} />
        Indicates this field is for our reviewer eyes only.
      </Text>
      <Text mt={20} fontSize={14}>
        <IoMdEye style={{ marginRight: 10 }} />
        Indicates this field is used on your public product page.
      </Text>
      {step.fields.map(field => {
        if (field.fields) {
          return field.wrapper(field.fields.map(f => renderContent(f)));
        }
        return renderContent(field);
      })}
    </Content>
  );
};

export default StepContent;
