import { Block, Flex, Text } from '@actovos-consulting-group/ui-core';
import React from 'react';
import styled, { css } from 'styled-components';

const Wrapper = styled(Flex)`
  ${({ $isFuture }) => css`
    ${$isFuture &&
    `
      opacity: 0.4;
    `}
  `}
`;

const ActiveMarker = styled.div`
  background: ${p => (p.$isActive ? p.theme.colors.primary : 'transparent')};
  height: 8px;
  border-radius: 5px 5px 0 0;
  width: 100%;
`;

const StepItem = ({ step, activeStep }) => {
  const isActive = activeStep.step === step.step;
  const isFuture = step.step > activeStep.step;
  return (
    <Block>
      <Wrapper
        $isFuture={isFuture}
        alignItems="center"
        px={20}
        pb={3}
        flexDirection={{ _: 'column', xs: 'row' }}
      >
        <Flex
          mr={{ _: 0, xs: 2 }}
          mb={{ _: 2, xs: 0 }}
          bg="textColor"
          borderRadius="50%"
          height={{ _: 30, xs: 50 }}
          width={{ _: 30, xs: 50 }}
          alignItems="center"
          justifyContent="center"
        >
          <Text fontSize={{ _: 14, xs: 30 }} color="white" fontWeight={500}>
            {step.step}
          </Text>
        </Flex>
        <Block flex={1}>
          <Text textAlign={{ _: 'center', xs: 'left' }}>Step {step.step}</Text>
          <Text
            textAlign={{ _: 'center', xs: 'left' }}
            fontSize={{ _: 12, xs: 20 }}
            fontWeight={500}
          >
            {step.navTitle}
          </Text>
        </Block>
      </Wrapper>
      <ActiveMarker $isActive={isActive} />
    </Block>
  );
};

export default StepItem;
