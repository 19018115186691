import { useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import AppContext from '../components/scenes/submit/app-context';

const useApplication = () => {
  const { appData, values, setValues } = useContext(AppContext);

  const setLocalValues = newValues => setValues({ ...values, ...newValues });
  const resetValues = () =>
    setValues({
      appID: uuidv4(),
      completedSteps: [],
    });

  return {
    values,
    resetValues,
    setLocalValues,
    appDoc: appData,
    appData: appData.data(),
  };
};

export default useApplication;
