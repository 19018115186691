import { Loadable } from '@actovos-consulting-group/ui-core';
import firebase from 'firebase/app';
import React from 'react';
import { useDocument } from 'react-firebase-hooks/firestore';
import { useLocalStorage } from 'react-use';
import { v4 as uuidv4 } from 'uuid';
import AppContext from './app-context';
import NewApp from './new-app';

const AppWithContext = ({ values, setValues }) => {
  const [appData, loading] = useDocument(
    firebase.firestore().doc(`Applications/${values.appID}`),
  );

  return (
    <Loadable loading={loading}>
      <AppContext.Provider value={{ appData, values, setValues }}>
        <NewApp />
      </AppContext.Provider>
    </Loadable>
  );
};

const SubmitAppPage = () => {
  const [values, setValues] = useLocalStorage('application', {
    appID: uuidv4(),
    completedSteps: [],
  });

  if (!values.appID) {
    return null;
  }

  return <AppWithContext values={values} setValues={setValues} />;
};

export default SubmitAppPage;
