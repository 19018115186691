import {
  Block,
  Clickable,
  Flex,
  Loadable,
  Text,
} from '@actovos-consulting-group/ui-core';
import { MdClose } from '@react-icons/all-files/md/MdClose';
import { RiUploadCloudLine } from '@react-icons/all-files/ri/RiUploadCloudLine';
import { truncate } from 'lodash';
import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';
import useApplication from '../../../../hooks/use-application';
import Dropzone from '../../../dropzone';

const Delete = styled(Clickable)(
  ({ theme }) => css`
    background-color: ${theme.colors.greyDarkest};
    color: white;
    height: 24px;
    width: 24px;
    border-radius: 50%;

    &:hover,
    &:focus {
      background-color: ${theme.colors.greyDark};
    }
  `,
);

const FileItem = styled(Flex)`
  max-width: 300px;
  overflow: hidden;
  white-space: nowrap;
`;

const UploadFiles = () => {
  const { appData, appDoc } = useApplication();

  const initialData = useMemo(() => {
    if (appData.docs) {
      return appData.docs.map(f => ({ name: f }));
    }
    return [];
  }, [appData]);

  const afterUpload = async files => {
    await appDoc.ref.set(
      {
        docs: files.map(f => f.name),
      },
      { merge: true },
    );
  };

  const handleDelete = async (e, onDelete, file) => {
    e.stopPropagation();
    const existing = appDoc.data().docs;

    await appDoc.ref.set(
      {
        docs: existing.filter(f => f !== file.name),
      },
      { merge: true },
    );
    await onDelete(e, file);
  };

  const accept = '.pdf,.jpg,.jpeg,.png';
  return (
    <Dropzone
      onUpload={afterUpload}
      subPath="docs"
      initialFiles={initialData}
      accept={accept}
    >
      {({ files, onDelete, uploading }) => (
        <Loadable size="sm" loading={uploading} style={{ height: 300 }}>
          <Flex flex={1} p={40}>
            {!!files.length && (
              <Block flex={1}>
                {files.map(file => (
                  <FileItem
                    borderRadius={10}
                    alignItems="center"
                    p={10}
                    bg="white"
                    key={file.name}
                    mb={2}
                  >
                    <Delete
                      onClick={e => {
                        handleDelete(e, onDelete, file);
                      }}
                    >
                      <MdClose size={16} />
                    </Delete>
                    <Text ml={3}>{truncate(file.name, { length: 25 })}</Text>
                  </FileItem>
                ))}
              </Block>
            )}
            <Block flex={1} style={{ textAlign: 'center' }}>
              <RiUploadCloudLine size={50} />
              <Text>Upload additional product docs</Text>
              <Text mt={10} fontSize={12}>
                Click to upload or drop files here.
              </Text>
              <Text mt={10} fontSize={12}>
                Accepts: {accept}
              </Text>
            </Block>
          </Flex>
        </Loadable>
      )}
    </Dropzone>
  );
};

export default UploadFiles;
