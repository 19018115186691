import { Block, Loadable, Text } from '@actovos-consulting-group/ui-core';
import { Redirect, Router } from '@reach/router';
import firebase from 'firebase/app';
import { navigate } from 'gatsby';
import React, { useState } from 'react';
import { Form, FormSpy } from 'react-final-form';
import { useDebounce } from 'react-use';
import { useAuthContext } from '../../../firebase/context';
import useApplication from '../../../hooks/use-application';
import LoginRegisterModal from '../../login-register-modal';
import BottomNav from './components/bottom-nav';
import Header from './components/header';
import StepContent from './components/step-content';
import STEPS from './steps';

const AutoSave = ({ values }) => {
  const { appDoc, appData } = useApplication();

  useDebounce(
    () => {
      if (appData?.status === 'submitted') {
        console.log('already submitted not saving');
        return;
      }

      appDoc.ref
        .set({ ...values, status: 'in-progress' }, { merge: true })
        .then(() => {
          console.log('saved');
        })
        .catch(error => {
          console.error('Error saving', error);
        });
    },
    1000,
    [values],
  );

  return null;
};

const StepRoute = ({ values, valid, stepID }) => {
  const findStep = STEPS.find(s => `${s.step}` === `${stepID}`);
  if (!findStep) {
    return <Redirect noThrow to="/submit/step/1" />;
  }
  const activeStep = findStep?.step;
  const prevStep = activeStep - 1;
  const canViewStep =
    !!activeStep &&
    (values.completedSteps.includes(prevStep) || activeStep <= 1);

  if (!canViewStep) {
    return <Redirect noThrow to={`/submit/step/${prevStep}`} />;
  }

  return (
    <Block mb={100}>
      <Header steps={STEPS} activeStep={findStep} />
      <StepContent step={findStep} />
      <BottomNav valid={valid} step={findStep} />
    </Block>
  );
};

const NewApp = () => {
  const [showLoginModal, setModal] = useState(false);
  const { user } = useAuthContext();
  const { appData, appDoc, loading, values, resetValues } = useApplication();

  const process = async userID => {
    await appDoc.ref.set(
      {
        User: firebase.firestore().doc(`/Users/${userID}`),
        status: 'submitted',
      },
      { merge: true },
    );
    resetValues();
    navigate(`/submit/fantastic?appID=${appDoc.id}`);
  };

  const onSubmit = async () => {
    if (!user.id) {
      setModal(true);
    } else {
      await process(user.id);
    }
  };

  return (
    <Block minHeight={500}>
      <Loadable loading={loading}>
        <Form
          onSubmit={onSubmit}
          // use logged in user email as a start if it exists
          initialValues={{ email: user.email || null, ...appData }}
        >
          {({ handleSubmit, valid }) => {
            return (
              <form onSubmit={handleSubmit}>
                <FormSpy subscription={{ values: true }} component={AutoSave} />
                <Router>
                  <StepRoute values={values} valid={valid} path=":stepID" />
                </Router>
              </form>
            );
          }}
        </Form>
      </Loadable>
      <LoginRegisterModal
        onSuccess={res => {
          process(res.user.uid);
        }}
        show={showLoginModal}
        onClose={() => {
          setModal(false);
        }}
      >
        <Text>
          To submit your product, please login or sign up for an account!
        </Text>
      </LoginRegisterModal>
    </Block>
  );
};

export default NewApp;
