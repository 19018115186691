import { Block, Text } from '@actovos-consulting-group/ui-core';
import React from 'react';
import useApplication from '../../../../hooks/use-application';
import AvatarUpload from '../../edit-profile/components/avatar-upload';

const UploadPhoto = () => {
  const {
    appDoc,
    values: { appID },
  } = useApplication();

  const afterUpload = async name => {
    await appDoc.ref.set(
      {
        productPhoto: name,
      },
      { merge: true },
    );
  };

  const handleDelete = async () => {
    await appDoc.ref.set(
      {
        productPhoto: null,
      },
      { merge: true },
    );
  };

  return (
    <Block width={300}>
      <Text textAlign="center" mb={2} fontSize="0.8rem" fontWeight={600}>
        Upload a Product Logo
      </Text>
      <Text mb={3} textAlign="center" fontSize="0.7rem">
        Accepts: .jpg,.jpeg,.png
      </Text>
      <AvatarUpload
        onDelete={handleDelete}
        onUpload={afterUpload}
        fileName="logo.jpg"
        storagePath={`applications/${appID}/product`}
      />
    </Block>
  );
};

export default UploadPhoto;
