import { Flex } from '@actovos-consulting-group/ui-core';
import React from 'react';
import StepItem from './header-step-item';

const Header = ({ steps, activeStep }) => {
  return (
    <Flex justifyContent="space-between">
      {steps.map(step => (
        <StepItem key={step.step} step={step} activeStep={activeStep} />
      ))}
    </Flex>
  );
};

export default Header;
