import { Block, Flex } from '@actovos-consulting-group/ui-core';
import React from 'react';
import { isSafari } from '../../../utils/browsers';
import { isEmailValid, isValidUrl, required } from '../../../utils/validations';
import SelectIndustries from './components/select-industries';
import UploadFiles from './components/upload-files';
import UploadPhoto from './components/upload-photo';
import UploadScreenshots from './components/upload-screenshots';

const INDUSTRIES = [
  'AI/ML',
  'Analytics',
  'AR/VR',
  'Blockchain/Crypto',
  'Communications',
  'Consumer',
  'DevOps',
  'Dev tools',
  'E-Commerce',
  'Entertainment',
  'Ed-tech',
  'Fintech',
  'Fitness/Wellness',
  'Gaming',
  'Government',
  'Healthtech',
  'Logistics',
  'Marketplace',
  'Mobile',
  'Productivity',
  'Real Estate',
  'SaaS',
  'Sales',
  'Social',
  'Other',
];

const STEPS = [
  {
    step: 1,
    navTitle: 'Your Pitch',
    formTitle: 'Give us your pitch.',
    instructions:
      'Tell us some basic details about your product and who you are.  Your elevator pitch should be very succinct and should tell us what your product does, how it solves a problem for an addressable market, and what you are needing.',
    fields: [
      {
        name: 'email',
        label: 'What is your product email?',
        required: true,
        validate: isEmailValid,
      },
      {
        name: 'founderName',
        label: 'What is your name?',
        required: true,
        validate: required,
      },
      {
        name: 'name',
        label: 'What is the name of your product?',
        required: true,
        validate: required,
      },
      {
        name: 'tagline',
        label: 'Does your product have a tagline? If so, what is it?',
        required: true,
        validate: required,
      },
      {
        name: 'url',
        label: 'What is the URL of your product website?',
        required: true,
        validate: isValidUrl,
        helpText: 'Ex: https://roastortoast.io',
      },
      {
        name: 'description',
        label: 'What is the product? Give us the "elevator pitch."',
        type: 'textarea',
        required: true,
        validate: required,
      },
    ],
    rightButton: {
      text: 'To Product Details',
      path: '/submit/step/2',
    },
    leftButton: {
      text: 'Cancel',
      path: '/',
    },
  },
  {
    step: 2,
    navTitle: 'Product Details',
    formTitle: 'Tell us more about your product.',
    instructions:
      'Let’s get into the nitty gritty!  We want to know more about your product, the problem it solves, who you’re building it for, and the market opportunity. We even want to know what tech stacks you used or if you’re using a no-code solution for your prototypes!',
    fields: [
      {
        name: 'launchDate',
        type: 'date',
        label: 'When is/was the launch date of your product?',
        required: true,
        validate: required,
        isPrivate: true,
        floatingLabel: !isSafari,
      },
      {
        name: 'problem',
        label: 'What is the problem that you’re solving for and why?',
        required: true,
        validate: required,
        type: 'textarea',
        isPrivate: true,
      },
      {
        name: 'marketOpportunity',
        label:
          'Who did you build the product for and what is the market opportunity?',
        required: true,
        validate: required,
        isPrivate: true,
      },
      {
        name: 'techStack',
        label: 'What tech stack or no-code platform did you use?',
        required: true,
        validate: required,
        isPrivate: true,
      },
      {
        name: 'competitors',
        label: 'Do you know who your competitors are? List them out here.',
        required: true,
        validate: required,
        type: 'textarea',
        isPrivate: true,
      },
      {
        name: 'industries',
        label: 'What industries best apply to your product?',
        validate: value => {
          if (!value) {
            return 'Required';
          }

          if (value.length > 4) {
            return 'Please only select up to 4';
          }

          return undefined;
        },
        helpText: 'Select up to 4',
        component: ({ input, field, error }) => (
          <SelectIndustries
            field={field}
            error={error}
            initialValues={input.value}
            onChange={input.onChange}
            industries={INDUSTRIES}
          />
        ),
      },
    ],
    rightButton: {
      text: 'To Links and Uploads',
      path: '/submit/step/3',
    },
    leftButton: {
      text: 'Back',
      path: '/submit/step/1',
    },
  },
  {
    step: 3,
    navTitle: 'Links & Uploads',
    formTitle: 'Upload your photos and links',
    instructions:
      'Let’s finish out your submission with links to your product’s social media account, app store links, product photos, and logos.',
    fields: [
      {
        wrapper: fields => (
          <Flex display={{ _: 'block', xs: 'flex' }} key="field-wrapper">
            <Block flex={1} mr={40}>
              {fields}
            </Block>
            <UploadPhoto />
          </Flex>
        ),
        fields: [
          {
            name: 'twitter',
            label: 'What is your product’s Twitter URL?',
            helpText: 'Ex: https://twitter.com/roast_or_toast',
            validate: isValidUrl,
          },
          {
            name: 'linkedIn',
            label: 'What is your product’s LinkedIn URL',
            helpText: 'Ex: https://www.linkedin.com/company/my-company',
            validate: isValidUrl,
          },
        ],
      },
      {
        name: 'crunchbase',
        label:
          'Does your company have a Crunchbase profile? If so, link us your profile!',
        helpText: 'Ex: https://www.crunchbase.com/organization/airbnb',
        validate: isValidUrl,
      },
      {
        name: 'appstore',
        label:
          'Is your product in an app store? If so, send us a link to your app in the store.',
        helpText: 'Ex: https://apps.apple.com/ug/app/tiktok/id835599320',
        validate: isValidUrl,
      },
      {
        name: 'demoAccount',
        label:
          'Is there a demo account we can use? If so, provide instructions here.',
        type: 'textarea',
        isPrivate: true,
      },
      {
        name: 'screenshots',
        label: 'Upload product screenshots.',
        component: () => <UploadScreenshots />,
      },
      {
        name: 'docs',
        label: 'Lastly, have any other relavent documents you want to share?',
        component: () => <UploadFiles />,
        isPrivate: true,
      },
    ],
    rightButton: {
      text: 'Submit Product',
      type: 'submit',
    },
    leftButton: {
      text: 'Back',
      path: '/submit/step/2',
    },
  },
];

export default STEPS;
